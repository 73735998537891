import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"

import { useTrackingNew } from "~/contexts/Tracking"
import { url } from "~/libs/style"

const easeOutExpo = (t: number) => {
  return t === 1 ? 1 : 1 * (-Math.pow(2, (-10 * t) / 1) + 1)
}

const scrollToPaidGame = (callback: () => void, scrollTo = 0) => {
  const startTime = window.performance.now()
  const duration = 1000
  const threshold = 2000
  const scrollFrom = window.pageYOffset > threshold ? threshold : window.pageYOffset

  let rafId = 0

  const move = (now: number) => {
    const time = Math.min(1, (now - startTime) / duration)
    const easing = easeOutExpo(time)
    const moveTo = easing * (scrollTo - scrollFrom) + scrollFrom

    window.scroll(0, moveTo)

    if (window.pageYOffset === scrollTo || now - startTime >= duration) {
      window.scroll(0, scrollTo)
      window.cancelAnimationFrame(rafId)
      callback?.()
      return
    }

    rafId = window.requestAnimationFrame(move)
  }

  rafId = window.requestAnimationFrame(move)
}

const useScrollState = (offset: number) => {
  const [visible, setVisible] = useState(false)
  const handleScroll = () => {
    setVisible(window.pageYOffset > offset)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [offset])

  return visible
}

type PageTopProps = {
  displayOffset: number
  scrollTo: number
}

export const PaidGameAnchor: React.FC<PageTopProps> = ({ displayOffset, scrollTo }) => {
  const visible = useScrollState(displayOffset)
  const { analyticsTrack } = useTrackingNew()

  return (
    <Wrapper
      visible={scrollTo > 0 && !visible}
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        scrollToPaidGame(() => {
          //do nothing
        }, scrollTo)
        analyticsTrack(event, "フローティングボタン", "ページトップ")
      }}
    >
      <ButtonLink href="#"></ButtonLink>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ visible: boolean }>`
  position: absolute;
  right: 10px;
  top: 40px;
  z-index: 99;
  opacity: 0;
  transition: opacity 0.5s;
  ${({ visible }) => visible && WrapperCssWhenVisible}
`

const WrapperCssWhenVisible = css`
  pointer-events: auto;
  opacity: 1;
`

const ButtonLink = styled.a`
  display: block;
  width: 96px;
  height: 96px;
  pointer-events: none;
  background: ${url("/assets/img/renew/paid_game_anchor.svg")};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`
