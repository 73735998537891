import getConfig from "next/config"
import Head from "next/head"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import { Accordion } from "~/app/Renew/Accordion"
import { Banner } from "~/app/Renew/Banner"
import { Container } from "~/app/Renew/Container"
import { GameCatalogNew } from "~/app/Renew/GameCatalog"
import { GameCatalogProps } from "~/app/Renew/GameCatalog"
import { History } from "~/app/Renew/History"
import { Note } from "~/app/Renew/Note"
import { NoticeCatalog } from "~/app/Renew/NoticeCatalog"
import { ProContainer } from "~/app/Renew/ProContainer"
import { Ranking } from "~/app/Renew/Ranking"
import { RankingItemProps } from "~/app/Renew/Ranking"
import { RankingPaid } from "~/app/Renew/RankingPaid"
import { AdElementCustomParent, AdFixed, AdRectangle } from "~/components/Ad"
import { Footer } from "~/components/Footer"
import { PageTop } from "~/components/PageTop"
import { PickupProps } from "~/components/Pickup"
import { ShortVideo } from "~/components/ShortVideo/ShortVideo"
import { adIds, distribution, distributionTitle } from "~/config"
import { ExperimentProvider } from "~/contexts/Experiment"
import { TrackingProvider } from "~/contexts/Tracking"
import { Fetcher } from "~/external/Fetcher"
import { useRepro } from "~/hooks/useRepro"
import { bustCache } from "~/libs/image"
import { mixin } from "~/libs/style"
import { BannersFreePresenter } from "~/presenter/BannersFreePresenter"
import { BannersPaidPresenter } from "~/presenter/BannersPaidPresenter"
import { ConfigPresenter } from "~/presenter/ConfigPresenter"
import { GameCatalogsPresenter } from "~/presenter/GameCatalogsPresenter"
import { GamesPresenter } from "~/presenter/GamesPresenter"
import { NoticeCatalogsPresenter } from "~/presenter/NoticeCatalogsPresenter"
import { PickupsPresenter } from "~/presenter/PickupsPresenter"
import { PopularGameCatalogsPresenter } from "~/presenter/PopularGameCatalogsPresenter"
import { RankingPresenter } from "~/presenter/RankingPresenter"
import { ShortVideoPresenter } from "~/presenter/ShortVideoPresenter"
import { BannersFreeRepository, BannersPaidRepository } from "~/repository/BannersRepository"
import { ConfigRepository } from "~/repository/ConfigRepository"
import { GameCatalogsRepository } from "~/repository/GameCatalogsRepository"
import { GamesRepository } from "~/repository/GamesRepository"
import { Genre } from "~/repository/GenresRepository"
import { GenresRepository } from "~/repository/GenresRepository"
import { NoticeCatalogsRepository } from "~/repository/NoticeCatalogsRepository"
import { PickupsRepository } from "~/repository/PickupsRepository"
import { PopularGameCatalogsRepository, PopularGameCatalogsSchema } from "~/repository/PopularGameCatalogsRepository"
import { RankingRepository } from "~/repository/RankingRepository"
import { ShortVideoRepository } from "~/repository/ShortVideoRepository"

import { Header } from "./Free/Header"
import { GamesProps } from "./Free/History"
import { GameCatalogsPresenter as PaidGameCatalogsPresenter } from "./Paid/model/presenter/GameCatalogsPresenter"
import { GameCatalogsRepository as PaidGameCatalogsRepository } from "./Paid/model/repository/GameCatalogsRepository"
import { HistoryPaid } from "./Renew/HistoryPaid"
type Experiment = {
  testId: string
  neta: string
}

interface ConfigProps {
  experiments: Experiment[]
}

type PaidGamesCatalogsProps = {
  gameId: number
  analyticsLabel: string
  title: string
  text: string
  url: string
  thumbnail: string
  image: {
    url: string
  }
  genres: number
}

export type FreeAppProps = {
  title: string
  ranking: RankingItemProps[]
  pickups: PickupProps
  gameCatalogs: GameCatalogProps["gameCatalogs"]
  games: GamesProps["games"]
  config: ConfigProps
  genres: Array<Genre>
  configDomain: {
    top: string
    paid: string
    free: string
  }
  noticesCatalogs: {
    id: string
    title: string
    url: string
  }[]
  shortVideos: {
    video_id: string
    is_horizontal: boolean
    game_title: string
    game_url: string
    banner_title: string
  }[]
  bannersFree: {
    app_id: number
    url: string
    banner: string
    analytics: {
      category: string
      label: string
    }
    isPaid: boolean
  }[]
  bannersPaid: {
    app_id: number
    url: string
    banner: string
    title: string
    analytics: {
      category: string
      label: string
    }
    isPaid: boolean
  }[]
  popularGameCatalogs: Array<PopularGameCatalogsSchema>
  paidGameCatalogs: Array<PaidGamesCatalogsProps>
}

const FreeApp: React.FC<FreeAppProps> = (props) => {
  const [gameCatalogOffset, setGameCatalogOffset] = useState(500)
  const [paidGameOffset, setPaidGameOffset] = useState(0)
  const gameCatalogRef = useRef<HTMLDivElement>(null)
  const paidGameRef = useRef<HTMLDivElement>(null)

  const {
    noticesCatalogs,
    pickups,
    gameCatalogs,
    genres,
    games,
    ranking,
    config,
    configDomain,
    shortVideos,
    bannersFree,
    bannersPaid,
    popularGameCatalogs,
    paidGameCatalogs
  } = props
  const adIdKey: keyof typeof adIds = distribution
  const adRect = adIds[adIdKey].rectangle
  const adRectMiddle = adIds[adIdKey].rectMiddle
  const adInfeedId = adIds[adIdKey].infeed
  const adFixed = adIds[adIdKey].fixed
  const baseUrl = configDomain.free
  const storageRef = useRef(null)
  const BannerPaid = styled(Banner)`
    margin-top: 20px;
    background: transparent;
    padding: 0 12px;
    img {
      border-radius: 6px;
    }
  `
  useRepro(distribution)

  useEffect(() => {
    const offsetAfterMount = gameCatalogRef?.current?.offsetTop
    offsetAfterMount && setGameCatalogOffset(offsetAfterMount)
  }, [gameCatalogOffset])
  useEffect(() => {
    const onLoad = () => {
      const offsetAfterMountPaidGame = paidGameRef?.current?.offsetTop
      if (offsetAfterMountPaidGame) {
        setPaidGameOffset(offsetAfterMountPaidGame)
      }
    }
    window.addEventListener("load", onLoad)
    return () => {
      window.removeEventListener("load", onLoad)
    }
  }, [])
  return (
    <ExperimentProvider configExperiments={config.experiments}>
      <Head>
        <meta property="og:title" content="無料ゲーム｜イージーゲーム" />
        <meta property="og:site_name" content="無料ゲーム" />
        <meta property="og:image" content={`${baseUrl}assets/img/game_ogp_free.png`} />
        <meta
          property="og:description"
          content="無料ですぐに遊べるかんたんゲーム！200タイトル以上のパズル、アクション、クイズ、脳トレ、スポーツなどの無料ゲームが充実。"
        />
        <meta property="og:url" content={baseUrl} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="無料ゲーム｜イージーゲーム" />
        <meta
          name="twitter:description"
          content="無料ですぐに遊べるかんたんゲーム！200タイトル以上のパズル、アクション、クイズ、脳トレ、スポーツなどの無料ゲームが充実。"
        />
        <link rel="canonical" href={baseUrl} />
        {pickups.contents.map((pickup, index) => (
          <link key={index} rel="preload" href={pickup.banner} as="image" />
        ))}

        {/* Fluct用 */}
        <link rel="preload" as="script" href="https://pdn.adingo.jp/p.js" />
        <link rel="preconnect" href="https://sh.adingo.jp" crossOrigin="use-credentials" />

        <title>無料ゲーム｜イージーゲーム</title>
        <meta
          name="description"
          content="無料ですぐに遊べるかんたんゲーム！200タイトル以上のパズル、アクション、クイズ、脳トレ、スポーツなどの無料ゲームが充実。"
        />
      </Head>
      <TrackingProvider categoryProp="ゲームトップ">
        <Container>
          <Header
            hasBackButton={false}
            headerTitle={distributionTitle}
            backUrl={configDomain.top}
            baseUrl={configDomain.free}
          />
          <SlideAndNotice>
            <Banner banner={bannersFree} configDomain={configDomain} ref={storageRef} />
            <NoticeCatalog noticesCatalogs={noticesCatalogs} />
          </SlideAndNotice>

          <History games={games} />
          <Ranking ranking={ranking} anchorScrollTo={paidGameOffset} />
          <ShortVideo videos={shortVideos} />
          <div ref={gameCatalogRef}>
            <GameCatalogNew gameCatalogs={gameCatalogs} genres={genres} adInfeedId={adInfeedId} />
          </div>
          <AdsCustom>
            <AdElementCustomParent id={adRectMiddle.id} url={adRectMiddle.url} size={adRectMiddle.size} />
          </AdsCustom>
          <div ref={paidGameRef}>
            <ProContainer baseUrl={baseUrl}>
              <Accordion
                items={[
                  {
                    title: "イージーゲーム Powered by ゲソてんとは？",
                    content:
                      "基本無料ゲームでありながら一部課金が発生するゲームです。プレイするのにau IDが必要になり、ダウンロードする必要もなくプレイ可能です。 「イージーゲーム powered by ゲソてん」は外部サイトに移動します"
                  }
                ]}
              />
              <HistoryPaid games={paidGameCatalogs} />
              <BannerPaid
                banner={bannersPaid}
                configDomain={configDomain}
                ref={storageRef}
                isPaid={true}
                title="キャンペーン"
              />
              <RankingPaid ranking={popularGameCatalogs} />
            </ProContainer>
          </div>
          <Note />
          <AdRectangle id={adRect.id} url={adRect.url} size={adRect.size} />
          <PageTop displayOffset={gameCatalogOffset} />
          <Footer />
          <AdFixed id={adFixed.id} url={adFixed.url} size={adFixed.size} />
          <script src={bustCache("/assets/js/legacy.js")}></script>
        </Container>
      </TrackingProvider>
    </ExperimentProvider>
  )
}

const getFreeData = async (): Promise<FreeAppProps> => {
  const {
    serverRuntimeConfig: { cmsEndpoint, cmsEndpointPaid, cmsApiKeyPaid, cmsApiKey, configDomain }
  } = getConfig()
  const fetcher = new Fetcher({ cmsEndpoint, cmsApiKey })
  const fetcherPaid = new Fetcher({
    cmsEndpoint: cmsEndpointPaid,
    cmsApiKey: cmsApiKeyPaid
  })

  const genresRepo = new GenresRepository(fetcher)
  const genres = await genresRepo.findAll()

  const gamesRepo = new GamesRepository(fetcher)
  const games = await gamesRepo.findAll()

  const gameCatalogsRepo = new GameCatalogsRepository(fetcher)
  const gameCatalogs = await gameCatalogsRepo.findAll()

  const noticeCatalogsRepo = new NoticeCatalogsRepository(fetcher)
  const noticesCatalogs = await noticeCatalogsRepo.findAll()

  const pickupsRepo = new PickupsRepository(fetcher)
  const pickups = await pickupsRepo.findAll()

  const rankingRepo = new RankingRepository(fetcher)
  const ranking = await rankingRepo.findAll()

  const configRepo = new ConfigRepository(fetcher)
  const config = await configRepo.findAll()

  const shortVideoRepo = new ShortVideoRepository(fetcher)
  const videos = await shortVideoRepo.findAll()

  const bannersPaidRepo = new BannersPaidRepository(fetcherPaid)
  const bannersPaid = await bannersPaidRepo.findAll()

  const bannerFreeRepo = new BannersFreeRepository(fetcher)
  const bannersFree = await bannerFreeRepo.findAll()

  const popularGameCatalogsRepo = new PopularGameCatalogsRepository(fetcherPaid)
  const popularGameCatalogs = await popularGameCatalogsRepo.findAll()

  const paidGameCatalogsRepo = new PaidGameCatalogsRepository(fetcherPaid)
  const paidGameCatalogs = await paidGameCatalogsRepo.findAll()

  return {
    genres,
    games: GamesPresenter.transform(games),
    gameCatalogs: GameCatalogsPresenter.transform(gameCatalogs),
    noticesCatalogs: NoticeCatalogsPresenter.transform(noticesCatalogs),
    pickups: PickupsPresenter.transform(pickups),
    ranking: RankingPresenter.transform(ranking),
    config: ConfigPresenter.transform(config),
    title: distributionTitle,
    configDomain: configDomain,
    shortVideos: ShortVideoPresenter.transform(videos),
    bannersFree: BannersFreePresenter.transform(bannersFree, configDomain),
    bannersPaid: BannersPaidPresenter.transform(bannersPaid),
    popularGameCatalogs: PopularGameCatalogsPresenter.transform(popularGameCatalogs),
    paidGameCatalogs: PaidGameCatalogsPresenter.transform(paidGameCatalogs)
  }
}

const AdsCustom = styled.div`
  max-width: 414px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding-bottom: 20px;
  background-color: #fff;
  ${mixin.shadow}
`
const SlideAndNotice = styled.div`
  max-width: 414px;
  margin: 0 auto;
  padding: 10px 12px;
  background-color: #fff;
  & + .ad-banner {
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      top: 0;
      left: 0;
      box-shadow: inset 0px 5px 7px -7px rgba(0, 0, 0, 0.45);
    }
  }
`

export { FreeApp, getFreeData }
